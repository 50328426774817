import { useState } from "react"
import classNames from "classnames"
import { trackAction } from "utils/analytics"
import { CLICKED, LOCATIONS } from "constants/track-actions"
import Link from "next/link"
import Modal from "react-bootstrap/Modal"
import Button from "../shared/Button"

import Close from "icons/close.svg"
import ChevronRight from "icons/chevron-right.svg"
import Chevron from "icons/chevron.svg"

import styles from "./Re-Rate.module.scss"

export const ReRateModal = ({ show, handleClose, current_company }) => {
  const [stillWorking, setStillWorking] = useState("")

  const { name, slug } = current_company || {}

  const modalClose = () => {
    handleClose()
  }

  const handleYes = () => {
    setStillWorking(stillWorking === "yes" ? "" : "yes")
  }

  const handleNo = () => {
    setStillWorking(stillWorking === "no" ? "" : "no")
  }

  return (
    <Modal show={show} onHide={modalClose} className={styles.modal}>
      <Modal.Body className={styles.body}>
        <div className={styles.body__header}>
          <div className={styles.body__header__required}>ACTION REQUIRED</div>
          <Close
            height="20"
            width="20"
            onClick={handleClose}
            className={styles.body__header__close}
          />
        </div>
        <h4>Please add another rating to continue using RepVue.</h4>
        <div className={styles.body__body}>
          {`We require our users to add a rating every 4 months to make sure we're providing you with current data.`}
        </div>
        {!current_company ? (
          <>
            <div className={styles.body__question}>
              We notice you&apos;ve not added a rating of your current org.
            </div>
            <Link
              href={`/rating/step1?company=${slug}&status=current`}
              className={`${styles.body__option} ${styles.body__current}`}
              onClick={() => {
                modalClose()
                trackAction(CLICKED, {
                  location: LOCATIONS.ACCESS_MODAL,
                  click_text: "Rate your current org",
                  click_url: `/rating/step1?company=${slug}&status=current`,
                  modal_name: null,
                })
              }}
              data-cy="rate-current-org"
            >
              Rate your current org
              <Chevron height={20} width={20} />
            </Link>

            <Link
              href="/rating/step1?not_working=true"
              className={styles.body__notWorkingLink}
              onClick={() => {
                modalClose()
                trackAction(CLICKED, {
                  location: LOCATIONS.ACCESS_MODAL,
                  click_text: "I'm currently not working",
                  click_url: "/rating/step1?not_working=true",
                  modal_name: null,
                })
              }}
            >
              I&apos;m currently not working
            </Link>
          </>
        ) : (
          <>
            <div className={styles.body__question}>
              {`Are you still working at ${name}?`}
            </div>
            <div className={styles.body__buttonBar}>
              <Button
                size="large"
                onClick={handleYes}
                className={classNames({
                  [`${styles.body__buttonBar__selected}`]:
                    stillWorking === "yes",
                })}
                variant="outline-dark"
                dataCy="still-working-yes"
              >
                Yes
              </Button>
              <Button
                size="large"
                onClick={handleNo}
                className={classNames({
                  [`${styles.body__buttonBar__selected}`]:
                    stillWorking === "no",
                })}
                variant="outline-dark"
              >
                No
              </Button>
            </div>
            {stillWorking === "no" && (
              <div className={styles.body__footer}>
                <div className={styles.body__question}>
                  {" "}
                  Which company would you like to rate?
                </div>
                <Link
                  href={`/rating/step1?company=${slug}`}
                  className={classNames(
                    styles.body__option,
                    styles.body__option__first
                  )}
                  onClick={() => {
                    modalClose()
                    trackAction(CLICKED, {
                      location: LOCATIONS.ACCESS_MODAL,
                      click_text: `Rate ${name} one last time`,
                      click_url: `/rating/step1?company=${slug}`,
                      modal_name: null,
                    })
                  }}
                >
                  {`Rate ${name} one last time`}
                  <ChevronRight height={20} width={10} />
                </Link>
                <Link
                  href="/rating/step1"
                  className={`${styles.body__option} ${styles.body__option__second}`}
                  onClick={() => {
                    modalClose()
                    trackAction(CLICKED, {
                      location: LOCATIONS.ACCESS_MODAL,
                      click_text: "Rate your new company",
                      click_url: "/rating/step1",
                      modal_name: null,
                    })
                  }}
                >
                  Rate your new company
                  <ChevronRight height={20} width={10} />
                </Link>
              </div>
            )}
            {stillWorking === "yes" && (
              <div className={styles.body__footer}>
                <div className={styles.body__question}>
                  Awesome! Please rate them again
                </div>
                <Link
                  href={`/rating/step1?company=${slug}&status=current`}
                  className={`${styles.body__option} ${styles.body__current}`}
                  onClick={() => {
                    modalClose()
                    trackAction(CLICKED, {
                      location: LOCATIONS.ACCESS_MODAL,
                      click_text: `Rate ${name} again`,
                      click_url: `/rating/step1?company=${slug}&status=current`,
                      modal_name: null,
                    })
                  }}
                  data-cy="rate-again"
                >
                  {`Rate ${name} again`}
                  <Chevron height={20} width={20} />
                </Link>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ReRateModal
